(function ($) {

    $.fn.select2user = function (opts) {
        return this.select2($.extend(true, {}, $.fn.select2user.defaults, opts));
    };

    var templateItem = function (user) {
        if (user.loading) {
            return 'Loading...';
        }

        if (user.id === '') {
            return user.text;
        }

        var imageUrl;
        if (user.SmallAvatarId !== null) {
            imageUrl = "/Image/GetImage/" + user.SmallAvatarId;
        } else {
            imageUrl = "/Content/Images/defaultavatar_small.png";
        }

        return "<img src='" + imageUrl + "' class='img-rounded' style='margin-right: 10px' alt='stuff' /><span>" + user.DisplayName + "</span>";
    };

    $.fn.select2user.defaults = {
        placeholder: 'Search for a user...',
        multiple: false,
        minimumInputLength: 3,
        maximumInputLength: 20,
        ajax: {
            //below is for example purposes
            //url: "/Recognize/Search",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    query: params.term
                };
            },
            processResults: function (data) {
                var mappedResults = _.map(data, function (result) {
                    return {
                        id: result.UserId,
                        UserId: result.UserId,
                        SmallAvatarId: result.SmallAvatarId,
                        MediumAvatarId: result.MediumAvatarId,
                        DisplayName: result.DisplayName,
                        DisplayAttributes: result.DisplayAttributes,
                        Title: result.Title,
                        UniqueId: result.UniqueId
                    };
                });
                return {
                    results: mappedResults,
                    pagination: {
                        more: false
                    }
                };
            }
        },
        id: function (user) {
            return user.UserId;
        },
        templateSelection: templateItem,
        templateResult: templateItem,
        escapeMarkup: function (m) {
            return m;
        }
    };

}(jQuery));